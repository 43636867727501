/* @import "./App.css"; */
@import "./datatable.css";

.table>:not(caption)>*>* {
    background-color: #f6f6f6 !important;
}

.space-header {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background: rgb(221, 221, 221);
    height: 43px;
    align-items: center;
}

.space-content-bg-color {
    background-color: #f6f6f6 !important;
}

.accordion-item:first-of-type .accordion-button {
    height: 36px !important;
}

legend {
    font-weight: 750 !important;
}

label,
.form-label {
    font-weight: 750 !important;
}