@import "./variables.scss";

body {
  background-color: #{$primary-bg} !important;
}

:root {
  --primary: #{$primary} !important;
  --cui-btn-hover-bg: #{$primary} !important;
}

.btn-primary {
  --cui-btn-hover-bg: #{$primary} !important;
  background-color: $primary !important;
  border-color: $primary !important;

  &:hover {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}
.text-color {
  color: $primary !important;
}
.bg-color {
  background-color: $primary !important;
}

.skeleton-loading {
  display: $display-flex !important;
  height: $auto !important;
  flex-direction: column !important;
  align-items: $center !important;
}

.skeleton-loading-item {
  width: $h-w-100pr !important;
  height: $height-50px !important;
  margin-bottom: $margin-bottom !important;
  background: $progress-background !important;
  background-size: 200% 100% !important;
  animation: loading 1.5s infinite !important;
}

.skeleton-loading-header {
  height: $auto !important;
}

@keyframes loading {
  0% {
    background-position: 200% $m-p-0px !important;
  }

  100% {
    background-position: -200% $m-p-0px !important;
  }
}

.button-container.d-flex {
  align-items: $center !important;
}
.Mark-all-read{
  font-weight: 700 !important;
  color: $logs-oldvalues-color !important;
  cursor: pointer !important;
  font-size: $font-size-16px !important;
  display: $display-flex !important;
  align-items: $center !important;
}

.datatable {
  border: $none !important;
}
.date-picker-container {
  display: $display-flex;
  align-items: $center;
}
.date-picker-input {
  width: 130px;
  padding: 0.375rem 0.75rem;
  text-align: $center;
}

.date-picker-input:focus {
  outline: $none;
  box-shadow: $none;
}
.react-datepicker__day--in-range,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: $primary !important;
}
.custom-modal {
  max-width: $h-w-50pr !important;
  margin: $auto !important;
}

.card-header {
  background: $color-white !important;
  font-weight: $font-weight !important;
  font-size: $font-size-22px !important;
}
.card {
  box-shadow: 0 2px 10px 0.5px rgba(0, 0, 0, 0.05); /* Adjust values as needed */
}
.table span {
  font-size: $font-size-13px !important;
}

.listgroup {
  text-align: start !important;

  ul li p {
    margin-bottom: 8px !important;
  }
}

.accordion-button {
  &:not(.collapsed) {
    background-color: $color-light-gray !important;
    color: $accordion-button-color !important;
  }

  &:focus {
    box-shadow: none !important;
    border: none !important;
  }
}

.filter-collapse {
  background: $color-white !important;
  border: $border-1px$border-solid#F1F1F4 !important;
  border-radius: 6px !important;
}
.filter-input{
  border: $border-1px$border-solid#DBDBDB !important;

}

.css-1p3m7a8-multiValue {
  background-color: $color-white !important;
  font-size: $font-size-16px !important;
  font-weight: $font-weight-bolder !important;
  height: $height-25px !important;
  margin-left: 5px !important;
}

.css-12a83d4-MultiValueRemove {
  border: $border-1px$border-solid$color-bright-red !important;
  border-radius: $border-radius !important;
  height: $height-12px !important;
  width: $height-12px !important;
  margin-top: 7px !important;
  color: $color-bright-red !important;
  margin-right: 5px !important;
  padding: $m-p-0px !important;
  margin-left: 3px !important;
}

.upload-box {
  grid-template-columns: repeat(3, 1fr) !important;
  display: grid !important;
  grid-column-gap: 20px !important;

  @media only screen and (max-width: 968px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.loadingspinnerbtn {
  padding: $m-p-0px 11px;
}

.color-box {
  width: $h-w-60px !important;
  height: $h-w-30px !important;
}
input[type="color"] {
  border-radius: $border-radius-default !important;
}

input[type="color"]::-moz-color-swatch {
  border-radius: $border-radius-default !important;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  border-radius: $border-radius-default !important;
}

input[type="color"]::-webkit-color-swatch {
  border-radius: $border-radius-small !important;
}

.back-btn {
  background-color: $color-gray-blue !important;
  font-size: $font-size-13px !important;
  color: $color-white !important;
}

.addnew-btn {
  background-color: $primary !important;
  color: $color-white !important;
  font-size: $font-size-13px !important;
}

.edit-btn {
  background-color: $color-teal !important;
  color: $color-white !important;
  font-size: $font-size-13px !important;
}
.download-zip-btn {
  background-color: $color-teal !important;
  color: $color-white !important;
  & span {
    font-size: $font-size-14px !important;
  }
  & img {
    margin-top: -2px;
    height: 22px !important;
    width: 22px !important;
  }
}

.delete-btn {
  background-color: $color-bright-red !important;
  color: $color-white !important;
  font-size: $font-size-13px !important;
}
.unblock-btn {
  background-color: #ffffff;
  color: $color-bright-red !important;
  border: 1.2px solid $color-bright-red !important;;
}
.square-checkbox {
  width: $square-checkbox-size !important;
  height: $square-checkbox-size !important;
  border: $border-1px$border-solid$disabled-color !important;
  border-radius: $border-radius-small !important;
  cursor: $cursor-pointer !important;
  margin-top: 15px !important;
  margin-left: 7px !important;
  padding: 5px !important;

  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.forgotpass {
  color: $forgotpass-color !important;

  &:hover {
    color: $primary !important;
  }
}

.signin-form-wrapper {
  width: 60% !important;
  margin: $m-p-0px $auto !important;
}
.password-toggle {
  position: $absolute !important;
  top: 50% !important;
  right: 0.5rem !important;
  transform: translateY(-50%) !important;
  cursor: $cursor-pointer !important;
}

.signin-image {
  width: $auto !important;
  object-fit: $object-fit-contain !important;
  height: $height-100vh !important;
  float: right !important;
}

.bold-border-table {
  border: $border-2px$border-solid$color-black !important;

  th,
  td {
    border: $border-2px$border-solid$color-black !important;
  }
}

.custom-dropdown-menu {
  width: $width-220px !important;
  align-items: $center !important;
}

.outline-border {
  border: $border-1px$border-solid$primary;
  border-radius: 4px !important;
}

.btn-delete {
  border: $border-1px$border-solid$color-bright-red;
  font-size: $font-size-12px !important;
  color: $color-bright-red;

  &:hover {
    color: $color-white !important;
    background-color: $color-bright-red !important;
  }
}

.notification-view {
  border: $primary !important;
  font-size: $font-size-12px !important;
  color: $primary !important;

  &:hover {
    color: $color-white !important;
    background-color: $primary !important;
  }
}
.nav-font{
  font-weight: $fw-600 !important;
  font-size: $font-size-1rem !important;
}

.dropdown-header{
  font-weight: $fw-600 !important;
  font-size: $font-size-1rem !important;
}
.dotted-list-item {
  position: relative !important;
  padding-left: 20px !important; 
}
.dropdown-toggle{
   font-weight: $fw-600 !important;
  font-size: 1.1rem !important;
}
.dotted-list-item::before {
  content: '•' !important; 
  position: $absolute !important;
  left: 4px !important;
  top: 44% !important;
  transform: translateY(-50%) !important;
  color: #dbdfe9 !important;
  font-size: $font-size-16px !important;
}


.content-mega-menu-inner {
  top: 0 !important;
  display: $display-flex !important;
  position: $absolute !important;
  top: 15px !important;
  right: 0 !important;
  background: $color-white !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.23) !important;
  border-radius: 0.475rem !important;
  color: #282f53 !important;
  padding: 0.5rem 0.9375rem;
  white-space: $w-s-nowrap !important;
  font-weight: $fw-500 !important;
  font-size: 1rem !important;
  text-decoration: dotted !important;
}
.settings-dropdown{
  background: $color-white !important;
  position: $absolute !important;
  top: 15px !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.23) !important;
  border-radius: 0.375rem !important;
  padding: 0.5rem 0.9375rem;
  min-width: 170px !important;
  font-weight: $fw-500 !important;
   white-space: $w-s-nowrap !important;
  font-size: 1rem !important;
}

.profile-dropdown{
  background: $color-white !important;
  min-width: $h-w-200px !important;
  max-width: $h-w-200px !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.23) !important;
  border-radius: 0.375rem !important;
  padding: 1rem 0.9375rem !important;
  font-weight: $fw-500 !important;
  white-space: normal !important;
  font-size: 1rem !important;
  margin-top: 6px !important;
}


.content-mega-menu-outer .dropdown-menu {
  border: 0 !important;
}

@media (max-width: 1000px) {
  .content-mega-menu-inner {
    display: list-item !important;
    width: fit-content !important;
    position: unset !important;
    overflow: scroll !important;
    padding: 0.5rem 0 !important;
  }

  .content-mega-menu-outer .dropdown-menu {
    position: $absolute !important;
    padding: 0 !important;
  }
}

.btn-font {
  font-size: $font-size-13px !important;
}

.btn.btn-sm {
  font-size: $font-size-13px !important;
}
.dropdown-toggle::after {
  font-size: $font-size-10px !important;
  display: $none !important;
}

.notification {
  border-radius: $border-radius !important;
  width: 35px !important;
  height: 35px !important;
  display: inline-flex;
  align-items: $center;
  justify-content: $center;
  padding: 7px !important;
  margin-top: 7px !important;

  &:hover {
    color: $primary;
    background: $notification-bg !important;
  }
}
.notification-dot {
  position: $relative !important;
  top: 11px !important;
  right: 11px !important;
  width: 5px !important;
  height: 5px !important;
  background-color: green !important;
  border-radius: $border-radius !important;
}

.custom-col {
  flex: 0px 0px $custom-col-width !important;
}

.room-box {
  height: $height-180px !important;
  border: $border-2px$border-solid$color-light-gray !important;
  border-radius: $border-radius-8px !important;
}

.image-box {
  width: $h-w-95pr !important;
  height: $h-w-95pr !important;
  object-fit: $object-fit-contain !important;
}

.submit-btn {
  width: 70px !important;
  height: $height-35px !important;
}

.catalog {
  height: $h-w-200px !important;
  width: $width-250px !important;
  position: $relative !important;
}

.catalog-page {
  cursor: $cursor-pointer !important;
  position: $absolute !important;
  top: -5px !important;
  right: -5px !important;
  background: $color-white !important;
  height: $h-w-30px !important;
  width: $h-w-30px !important;
  border-radius: $border-radius !important;
  text-align: $center !important;
}

.add-update-form {
  width: $h-w-95pr !important;
  border: $border-1px$border-solid$color-light-gray !important;
}

.form-title {
  border: $border-1px$border-solid$color-light-gray !important;
}

.w-98 {
  width: $h-w-98pr !important;
}

.filter-label {
  max-width: $width-max-content !important;

}

.error-code {
  font-size: $font-size-5rem !important;
  font-family: sans-serif !important;
}

.not-found {
  margin-top: 10rem !important;
}

.icon-button {
  margin: 6px $auto !important;
}

.cursor-pointer {
  cursor: $cursor-pointer !important;
}

.select-account {
  width: 500px !important;
  padding: 30px !important;
  border-radius: 20px !important;
}

.account-div {
  border: $border-1px$border-solid$account-div-border !important;
  padding: 10px !important;
  border-radius: $border-radius-10px !important;
  cursor: $cursor-pointer !important;
}

.activity-card {
  width: 87% !important;
  margin: $m-p-0px $auto;
  background-color: $color-white !important;
}
.activity-card-product{
  width: 93.5% !important;
  margin: $m-p-0px $auto;
  background-color: $color-white !important;
}
.fit-content {
  max-width: $width-fit-content !important;
}

.company-logo {
  height: $h-w-60px !important;
}

.catalog-dropshadow {
  height: 53px !important;
}
.nav-scroll-container {
  overflow-x: $auto;
  overflow-y: $overflow-hidden;
  white-space: nowrap;
}

.nav-scroll-container .nav-item {
  display: inline-block;
}
.catalog-image-div {
  background: $color-white !important;
  border-radius: $border-radius-10px !important;
  padding: 15px !important;
}

.details-edit {
  background-color: $color-teal !important;
}
.campaign {
  background-color: $color-light-gray !important;
}

.campaign-width {
  width: 48% !important;
}

.subuserview-modal {
  height: 300px !important;
}

.organization-details {
  background: $organization-details-bg !important;
  height: $h-w-22px !important;
  width: $h-w-22px !important;
  line-height: $h-w-22px !important;
  border-radius: $border-radius !important;
  text-align: $center !important;
}

.logo-container {
  width: $h-w-100pr !important;
  height: 215px !important;
  border: $border-2px$border-solid$color-light-gray !important;
  border-radius: $border-radius-8px !important;
  display: $display-flex !important;
  justify-content: $center !important;
  align-items: $center !important;
  overflow: $overflow-hidden !important;
}

.logo-image {
  width: $h-w-95pr !important;
  height: $h-w-95pr !important;
  object-fit: $object-fit-contain !important;
}

.view-div {
  position: initial !important;
}

.lebal-font {
  font-size: $font-size-14px !important;
}

.input-filed {
  height: $height-35px !important;
  background: $color-light-gray !important;
}
.height-42-px {
  height: $height-42px !important;
}
.bg-fg-color {
  background: $color-light-gray !important;
  height: $height-35px !important;
  padding: $m-p-0px !important;
  // border: 1.5px$border-solid$color-black !important;
}

.lebal-name {
  background: $color-light-gray !important;
  height: $height-35px !important;
  border: $lebal-name-border !important;
}

.edit-details {
  height: $h-w-200px !important;
  width: $width-220px !important;
  position: $relative !important;
}
.help-text {
  font-size: $font-size-12px !important;
  margin-top: 5px !important;
  color: $forgotpass-color !important;
}
.product-image {
  height: $h-w-200px !important;
}

.product-view {
  background-color: $color-light-gray !important;
}

.upload-product {
  width: 47% !important;
}

.view-image-div {
  width: $h-w-100pr !important;
  height: $h-w-200px !important;
  border: $border-1px$border-solid$disabled-color !important;
  margin-top: 10px !important;
}

.view-image {
  max-width: $h-w-100pr !important;
  max-height: $h-w-100pr !important;
  transform: translate(-50%, -50%) !important;
}

.visitor-div {
  width: 85px !important;
  height: 75px !important;
}

.visitor-image {
  width: $h-w-60px !important;
  height: $h-w-60px !important;
}

.visitor-body {
  padding: 15px 15px $m-p-0px 15px !important;
}

.skeleton-div {
  max-height: 340px !important;
  max-width: 90% !important;
}

.roomimage-div {
  max-height: 351.5px !important;
}

.room-image {
  width: $h-w-100pr !important;
  height: $h-w-98pr !important;
  object-fit: $object-fit-contain !important;
}

.room-details {
  max-height: $height-409px !important;
  overflow-y: $auto !important;
  margin-top: -8px !important;
}

.output-image-div {
  width: $h-w-100pr !important;
  height: $height-180px !important;
  border: $border-2px$border-solid$color-light-gray !important;
  border-radius: $border-radius-8px !important;
  display: $display-flex !important;
  justify-content: $center !important;
  align-items: $center !important;
  overflow: $overflow-hidden !important;
}

.aiimage {
  max-height: 460px !important;
  width: $h-w-100pr !important;
  object-fit: $object-fit-contain !important;
}

.Aiimage-details {
  max-height: $height-409px !important;
  overflow-y: $auto !important;
  margin-top: -8px !important;
}

.assign-role {
  margin-left: 2rem !important;
}

.select-all {
  margin-top: 0.5rem !important;
}
.w-90{
  width: 96% !important;
  margin: 0 auto;
}
.permissions {
  margin-top: 0.8rem !important;
  margin-right: 5px !important;
}

.refresh {
  width: 330px !important;
}

.lable-interval {
  margin-top: 12px !important;
}

.select-interval {
  width: $h-w-200px !important;
  height: 49px !important;
}

.product-col {
  height: 519px !important;

  Image {
    height: $h-w-98pr !important;
    width: $h-w-98pr !important;
  }
}

.product-details-scroll{
  overflow-y: $auto !important;
}
.product-details{
  max-height: 520px;
}
.visitors-analytics {
  height: $h-w-350px !important;
}

.recent-orders {
  height: 218px !important;
}

.top-product-view {
  fill: $primary !important;
}

.top-product-image {
  width: 78px !important;
  height: 78px !important;
  display: $display-block !important;
  margin: $auto !important;
}

.table-width {
  width: 700px !important;

  tbody {
    border: $border-1px$border-solid$color-light-gray !important;
  }
}
.custom-timeline-event * {
  box-shadow: none !important;
}

/* Ensure the table retains its white background */
.custom-timeline-event table {
  background-color: #ffffff !important;
}
.custom-timeline-event table, 
.custom-timeline-event table tr,
.custom-timeline-event table td {
  background-color: #ffffff !important;
}
.activity-table{
  margin-left: -11px !important;
  margin-top: -15px !important;
}
.logs-oldvalues {
  width: $h-w-350px !important;
  color: $logs-oldvalues-color !important;
  text-decoration: $line-through !important;
  padding-left: $padding-17px !important;
}

.logs-newvalues {
  width: $h-w-350px !important;
  padding-left: $padding-17px !important;
  font-weight: $font-weight !important;
}

.arrow-repeat{
  margin-left: 6px !important;
}
.create-icon{
  height: 16px !important;
  width: 16px !important;
  margin-left: 6px !important; 
}

.blue-text {
  color: $primary !important;
}
.logs-main {
  border-left: $border-1px$border-solid$activity-border-color !important;

  div {
    margin-bottom: $margin-bottom !important;
  }
}
.activity-log-for-product {
  width: $h-w-93pr;
  margin: 0 $auto;
}
.chart-container {
  width: $h-w-100pr !important;

  @media (min-width: 768px) and (max-width: 991px) {
    width: $h-w-50pr !important;
  }
}

.custom-checkbox {
  display: $display-flex !important;
  align-items: $center !important;
}

.custom-checkbox input[type="checkbox"] {
  display: $none !important;
}

.custom-checkbox label {
  display: $display-flex !important;
  align-items: $center !important;
  cursor: $cursor-pointer !important;
  user-select: $none !important;
}

.custom-checkbox label::before {
  content: "";
  display: inline-block;
  width: $h-w-16px !important;
  height: $h-w-16px !important;
  border: $border-2px$border-solid$color-light-gray !important;
  border-radius: 4px !important;
  background-color: #c6c6c6 !important;
  margin-right: 8px !important;
  transition:
    background-color 0.1s,
    border-color 0.1s !important;
}

.custom-checkbox input[type="checkbox"]:checked + label::before {
  background-color: $primary !important;
  border-color: $primary !important;
  content: "✔" !important;
  color: $color-white !important;
  display: $display-flex !important;
  align-items: $center !important;
  justify-content: $center !important;
}

.custom-checkbox label span {
  color: $forgotpass-color !important;
  transition: color 0.1s !important;
}

.custom-checkbox input[type="checkbox"]:checked + label span {
  color: #262626 !important;
}

.zip-help {
  background-color: $zip-hepl-bg !important;
  & h5 {
    margin-top: 18px !important;
    color: $color-gray-blue !important;
    font-size: $font-size-14px !important;
  }
  & p {
    color: $color-gray-blue !important;
    font-size: $font-size-14px !important;
    margin-bottom: 50px !important;
  }
}
.no-scroll {
  overflow: $overflow-hidden;
}
@media (max-width: 767.98px) {
  .signin-logo {
    width: $h-w-50pr;
  }
}
.TimelineBlip {
  position: absolute !important;
  top: 0px !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 1px !important;
  background: #e9f0f5 !important;
  border: 5px solid grey !important;
  display: flex !important;
}
.balancecount {
  margin-top: 3.4rem;
}
.custom-close-icon {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.custom-close-icon:hover {
  transform: scale(1.1);
  content: url("../../public/images/svg/closeiconblue.svg");
  /* Change icon on hover */
}
.min-height-card {
  min-height: 650px; 
}
.min-height-card-visitor{
  min-height: 450px; 
}
.modal-header {
  padding-right: 1rem !important; 
}
.nav-line-tabs-2x{
  border-bottom-width: 3px !important;
}

.full-image {
  width: 100% !important;
  height: 98% !important;
}
.table-dashed{
 border-top-style: dashed !important;
 border-color: #e9edf4 !important;
}
.badge-light-success {
  background-color: #DFFFEA !important;
}
.badge-light-danger {
  background-color: #FFEEF3 !important;
} 