$primary: #387efe;
$profile-bg: #0d6efd ;
$color-white: #FFFFFF;
$primary-bg: #FEFEFE;
$color-light-gray: #F0F0F5;
$disabled-color: #ccc;
$color-black: #000000;
$color-bright-red: #E82646;
$color-teal: #09AD95;
$color-gray-blue: #99A1B7;
$catalog-page-bg: #00bfff;
$account-div-border: #9EE9B8;
$progress-background: linear-gradient(90deg, #dfdbdb 25%, #dfdbdb 50%, #dfdbdb 75%);
$pagination-chevron-hover-color: #c3c3c3;
$accordion-button-color: #252b36;
$notification-bg: #D7ECFB ;
$forgotpass-color: #99A1B7;
$organization-details-bg: #99A1B7;
$activity-arrow-color: #99A1B7;
$logs-oldvalues-color: gray;
$activity-date-color: #B4B5C0;
$activity-border-color: #CED1DB;
$zip-hepl-bg: #f7f7f7;

$center: center;
$auto: auto;
$none: none;
$overflow-hidden: hidden;
$font-weight: bold;
$font-weight-bolder: bolder;
$line-through: line-through;
$object-fit-contain: contain;
$relative: relative;
$absolute: absolute;
$display-flex: flex;
$display-block: block;
$cursor-pointer: pointer;
$w-s-nowrap: nowrap;

$font-size-13px: 13px;
$font-size-12px: 12px;
$font-size-22px: 22px;
$font-size-16px: 16px;
$font-size-18px: 18px;
$font-size-10px: 10px;
$font-size-36px: 36px;
$font-size-5rem: 5rem;
$font-size-14px: 14px;
$font-size-1rem: 1.2rem;

$fw-600: 600;
$fw-500: 500;

$h-w-100pr: 100%;
$h-w-95pr: 95%;
$h-w-98pr: 98%;
$h-w-93pr: 93%;
$h-w-60px: 60px;
$h-w-50pr: 50%;
$h-w-350px: 350px;
$h-w-200px: 200px; 
$h-w-40px: 40px;
$h-w-30px: 30px;
$h-w-20px: 20px;
$h-w-22px: 22px;
$h-w-16px: 16px;
 
$height-50px: 50px;
$height-100vh: 100vh;
$height-35px: 35px;
$height-42px: 42px;
$height-180px: 180px;
$height-409px: 409px;
$height-25px: 25px;
$height-12px: 12px;

$width-220px: 220px;
$width-250px: 250px;
$custom-col-width: calc(25% - 0.5rem);
$width-max-content:  max-content;
$width-fit-content: fit-content;

$border-radius: 50%;
$border-radius-8px: 8px;
$border-radius-10px: 10px;
$border-radius-default: 5px;
$border-radius-small: 4px;
$square-checkbox-size: 12px;

$m-p-0px: 0px;

$margin-bottom: 10px;

$padding-17px: 17px;

$border-1px: 1px;
$border-2px: 2px;
$border-solid: solid;

$lebal-name-border: $disabled-color ;
$pagination-disabled-color: $disabled-color;
