.rdt_TableHead,
.rdt_TableBody,
.bPokRt {
    font-size: 15.5px !important;
    background-color: transparent !important;
}

.LYwHa {
    min-height: 440px !important;
}
.rdt_Table{
    margin-top: 15px !important;
    border: 1px solid #F1F1F2 !important;
    border-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.isdzTY {
    border: 1px solid #F1F1F2 !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}